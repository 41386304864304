import {useState,useEffect} from 'react'
import '../css/custom.css'
import '../css/page.min.css'
import '../css/style.css'
import '../css/contact.css'
import { Helmet } from 'react-helmet-async'












const Terms = ()=>{

useEffect(()=>{
    document.title = 'Boltzmann | Terms'
    window.scrollTo(0, 0)
})


const h4styles ={
    fontWeight: "bold",

    fontSize: "1.52344rem",
    textDecoration:'none'
}



return(

<div>
<Helmet>
<title>Boltzmann | Terms </title>
    <meta property="description" content="This Web site contains proprietary notices and copyright information, the terms of which must be observed and followed. Please see the tab entitled “Copyright and trademark information” for related information. BOLTZMANN Labs grants you a non-exclusive, non-transferable, limited permission to access and display the Web pages within this site as a customer or potential customer of BOLTZMANN Labs provided you comply with these Terms of Use, and all copyright, trademark, and other proprietary notices remain intact."/>
    <meta property ="og:description" content = "This Web site contains proprietary notices and copyright information, the terms of which must be observed and followed. Please see the tab entitled “Copyright and trademark information” for related information. BOLTZMANN Labs grants you a non-exclusive, non-transferable, limited permission to access and display the Web pages within this site as a customer or potential customer of BOLTZMANN Labs provided you comply with these Terms of Use, and all copyright, trademark, and other proprietary notices remain intact."/>
    <meta property ="og:title" content ="Boltzmann-Terms" />
    <meta property ="og:url" content = "https://boltzmann.co/Terms" />
    <meta property ="twitter:description" content= "This Web site contains proprietary notices and copyright information, the terms of which must be observed and followed. Please see the tab entitled “Copyright and trademark information” for related information. BOLTZMANN Labs grants you a non-exclusive, non-transferable, limited permission to access and display the Web pages within this site as a customer or potential customer of BOLTZMANN Labs provided you comply with these Terms of Use, and all copyright, trademark, and other proprietary notices remain intact."/>
    <meta property ="twitter:title" content ="Boltzmann-Terms" />
    <meta property ="twitter:url" content = "https://boltzmann.co/Terms" />
</Helmet>
    <main class="main-content" id="terms">

    <section id="section-features" class="terms-section">

        <div class="container" style={{color: "#501f84"}}>
            <h3
                style={{textAlign: "center",fontFamily: "Inter,sans-serif",fontWeight: "bold",marginTop:"7%",color: "#501F84",fontSize: "1.75781rem"}}>
                TERMS OF
                USAGE</h3>

            <span style={{fontWeight: "bold"}}>Last Updated: June 2021</span>

            <h4  style={h4styles}>Overview</h4>

            <p>The following are the terms of an agreement between you and Boltzmann Labs Private Limited (referred

                to as BOLTZMANN Labs hereafter). By accessing, or using this Web site, you acknowledge that you have

                read, understand, and agree to be bound by these terms and to comply with all applicable laws and

                regulations, including export and re-export control laws and regulations. If you do not agree to

                these terms, please do not use this Web site.

            </p>

            <p>BOLTZMANN Labs may, without notice to you, at any time, revise these Terms of Use and any other

                information contained in this Web site. BOLTZMANN Labs may also make improvements or changes in the

                products, services, or programs described in this site at any time without notice.

            </p>

            <h4 style={h4styles}>General</h4>

            <p>This Web site contains proprietary notices and copyright information, the terms of which must be

                observed and followed. Please see the tab entitled “Copyright and trademark information” for related

                information.

            </p>

            <p>BOLTZMANN Labs grants you a non-exclusive, non-transferable, limited permission to access and display

                the Web pages within this site as a customer or potential customer of BOLTZMANN Labs provided you

                comply with these Terms of Use, and all copyright, trademark, and other proprietary notices remain

                intact. You may only use a crawler to crawl this Web site as permitted by this Web site’s robots.txt

                protocol, and BOLTZMANN Labs may block any crawlers at its sole discretion. The use authorized under

                this agreement is non-commercial in nature (e.g., you may not sell the content you access on or

                through this Web site.) All other use of this site is prohibited.

            </p>

            <p>Except for the limited permission in the preceding paragraph, BOLTZMANN Labs does not grant you any

                express or implied rights or licenses under any patents, trademarks, copyrights, or other

                proprietary or intellectual property rights. You may not mirror any of the content from this site on

                another Website or in any other media. Any software and other materials that are made available for

                downloading, access, or other use from this site with their own license terms will be governed by

                such terms, conditions, and notices. Your failure to comply with such terms or any of the terms on

                this site will result in automatic termination of any rights granted to you, without prior notice,

                and you must immediately destroy all copies of downloaded materials in your possession, custody or

                control.

            </p>



            <h4 style={h4styles}>Rules of conduct</h4>

            <p>Your use of app.boltchem.com and any related BOLTZMANN Labs websites (BOLTZMANN Labs Websites) is

                subject to all applicable local, state, national and international laws and regulations, and you

                agree not to violate such laws and regulations. Any attempt by any person to deliberately damage

                BOLTZMANN Labs Websites is a violation of criminal and civil laws. BOLTZMANN Labs reserves the right

                to seek damages from any such person to the fullest extent permitted by law.

            </p>

            <p>You agree not to post or transmit through BOLTZMANN Labs Websites any material or content that

                violates or infringes in any way the rights of others or solicits, encourages or promotes the use of

                illegal substances or activities, which is unlawful, threatening, abusive, harassing, defamatory,

                libelous, derogatory, invasive of privacy or publicity rights, vulgar, obscene, bigoted or hateful,

                profane, scandalous, pornographic, indecent or otherwise objectionable, gives rise to civil or

                criminal liability or otherwise violates any applicable law.

            </p>

            <p>You may not impersonate another user, use another user’s BoltChem id, permit someone else to use your

                BoltChem id, attempt to capture or guess other users’ passwords, forge headers or otherwise

                manipulate identifiers for the purpose of disguising the origin of any content you transmit, conduct

                fraudulent business operations or practices, or promote or conceal unlawful conduct.

            </p>

            <p>You may not engage in any activity on BOLTZMANN Labs Websites which restrict or inhibit any other

                user from using or enjoying BOLTZMANN Labs Websites by “hacking,” “cracking,” “spoofing,” or

                defacing any portions of BOLTZMANN Labs Websites. You may not use BOLTZMANN Labs Websites to stalk

                or harass another person or entity.

            </p>

            <p>You may not post or transmit through BOLTZMANN Labs Websites advertising or commercial solicitations;

                promotional materials relating to website or online services which are competitive with BOLTZMANN

                Labs and/or BOLTZMANN Labs Websites; software or other materials that contain viruses, worms, time

                bombs, Trojan horses, or other harmful or disruptive components, political campaign materials; chain

                letters; mass mailings, spam mail, any robot, spider, site search/retrieval application, or other

                manual or automatic device or process to retrieve, index, “data mine,” or in any way reproduce or

                circumvent the navigational structure or presentation of BOLTZMANN Labs Websites or contents. You

                may not harvest or collect information about website visitors without their express written consent.

            </p>



            <h4 style={h4styles}>Disclaimer</h4>

            <p>From time to time, this Web site may contain technical inaccuracies or typographical errors, and we

                do not warrant the accuracy of any posted information. Please confirm you are using the most

                up-to-date pages on this Web site, and confirm the accuracy and completeness of information before

                using it to make decisions relating to services, products, or other matters described on this and

                related Web sites.

            </p>

            <p>If any term in this Terms of Use is found by competent judicial authority to be unenforceable in any

                respect, the validity of the remainder of this Terms of Use will be unaffected, provided that such

                unenforceability does not materially affect the parties’ rights under this Terms of Use.

            </p>



            <h4  style={h4styles}>Confidential information</h4>

            <p>BOLTZMANN Labs does not want to receive confidential or proprietary information from you through our

                Website (or our other websites). If BOLTZMANN Labs is involved in a merger, acquisition, or sale of

                assets, we’ll continue to ensure the confidentiality of your personal information and give affected

                users notice before personal information is transferred or becomes subject to a different privacy

                policy. We use encryption to keep your data private while in transit. Information collection,

                storage, and processing practices, including physical security measures are reviewed in order to

                prevent unauthorized access to our systems. Access is restricted to personal information to

                BOLTZMANN Labs employees, contractors, and agents who need that information in order to process it.

                Anyone with this access is subject to strict contractual confidentiality obligations and may be

                disciplined or terminated if they fail to meet these obligations. Your personal information is NOT

                shared with companies, organizations, or individuals outside of BOLTZMANN Labs.

            </p>



            <h4 style={h4styles}>Global availability</h4>

            <p>Information BOLTZMANN Labs publishes on the Internet may contain references or cross references to

                BOLTZMANN Labs products, programs and services that are not announced or available in your country.

                Such references do not imply that BOLTZMANN Labs intends to announce or make available such

                products, programs, or services in your country. Please consult your local BOLTZMANN Labs for

                information regarding the products, programs, and services that may be available to you.

            </p>



            <h4  style={h4styles}>Business relationships</h4>

            <p>This Website may provide links or references to non-BOLTZMANN Labs Web sites and resources. BOLTZMANN

                Labs makes no representations, warranties, or other commitments or endorsements whatsoever about any

                non-BOLTZMANN Labs Web sites or third-party resources that may be referenced, accessible from, or

                linked to any BOLTZMANN Labs site. In addition, BOLTZMANN Labs is not a party to or responsible for

                any transactions you may enter into with third parties, even if you learn of such parties (or use a

                link to such parties) from an BOLTZMANN Labs site. When you access a non-BOLTZMANN Labs Website,

                even one that may contain the BOLTZMANN Labs-logo, please understand that it is independent from

                BOLTZMANN Labs, and that BOLTZMANN Labs does not control the content on that Website. It is up to

                you to take precautions to protect yourself from viruses, worms, Trojan horses, and other

                potentially destructive programs, and to protect your information.

            </p>



            <h4 style={h4styles}>Linking to this site</h4>

            <p>BOLTZMANN Labs consents only to links to this Website in which the link and the pages that are

                activated by the link do not: (a) create frames around any page on this Website or use other

                techniques that alter in any way the visual presentation or appearance of any content within this

                site; (b) misrepresent your relationship with BOLTZMANN Labs; (c) imply that BOLTZMANN Labs approves

                or endorses you, your Website, or your service or product offerings; and (d) present false or

                misleading impressions about BOLTZMANN Labs or otherwise damage the goodwill associated with the

                BOLTZMANN Labs name or trademarks. As a further condition to being permitted to link to this site,

                you agree that BOLTZMANN Labs may at any time, in its sole discretion, terminate permission to link

                to this Web site. In such event, you agree to immediately remove all links to this Web site and to

                cease any related use of BOLTZMANN Labs trademarks.

            </p>







            <h4 style={h4styles}>DISCLAIMER OF WARRANTY</h4>

            <p>USE OF THIS SITE IS AT YOUR SOLE RISK. ALL MATERIALS, INFORMATION, PRODUCTS, SOFTWARE, PROGRAMS, AND

                SERVICES ARE PROVIDED "AS IS," WITH NO WARRANTIES OR GUARANTEES WHATSOEVER. BOLTZMANN LABS EXPRESSLY

                DISCLAIMS TO THE FULLEST EXTENT PERMITTED BY LAW ALL EXPRESS, IMPLIED, STATUTORY, AND OTHER

                WARRANTIES, GUARANTEES, OR REPRESENTATIONS, INCLUDING, WITHOUT LIMITATION, THE WARRANTIES OF

                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON INFRINGEMENT OF PROPRIETARY AND

                INTELLECTUAL PROPERTY RIGHTS. WITHOUT LIMITATION, BOLTZMANN LABS MAKES NO WARRANTY OR GUARANTEE THAT

                THIS WEB SITE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE.

            </p>

            <p>YOU UNDERSTAND AND AGREE THAT IF YOU DOWNLOAD OR OTHERWISE OBTAIN MATERIALS, INFORMATION, PRODUCTS,

                SOFTWARE, PROGRAMS, OR SERVICES FROM THIS WEB SITE, YOU DO SO AT YOUR OWN DISCRETION AND RISK AND

                THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGES THAT MAY RESULT, INCLUDING LOSS OF DATA OR

                DAMAGE TO YOUR COMPUTER SYSTEM. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF WARRANTIES, SO THE

                ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.

            </p>



            <h4 style={h4styles}>LIMITATION OF LIABILITY</h4>

            <p>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL BOLTZMANN Labs BE LIABLE TO ANY

                PARTY FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES OF ANY TYPE

                WHATSOEVER RELATED TO OR ARISING FROM THIS WEB SITE OR ANY USE OF THIS WEB SITE, OR OF ANY SITE OR

                RESOURCE LINKED TO, REFERENCED, OR ACCESSED THROUGH THIS WEB SITE, OR FOR THE USE OR DOWNLOADING OF,

                OR ACCESS TO, ANY MATERIALS, INFORMATION, PRODUCTS, OR SERVICES, INCLUDING, WITHOUT LIMITATION, ANY

                LOST PROFITS, BUSINESS INTERRUPTION, LOST SAVINGS OR LOSS OF PROGRAMS OR OTHER DATA, EVEN IF

                BOLTZMANN Labs IS EXPRESSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THIS EXCLUSION AND WAIVER OF

                LIABILITY APPLIES TO ALL CAUSES OF ACTION, WHETHER BASED ON CONTRACT, WARRANTY, TORT, OR ANY OTHER

                LEGAL THEORIES.

            </p>

        </div>

    </section>



</main>
</div>
)
}
 
export default Terms