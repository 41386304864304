import {useNavigate} from "react-router-dom"



const Footer =()=>{

const Nav= useNavigate()



    return(
    <div>
        <footer class="footer">
  <div class="container">
    <div class="row gap-y align-items-center">

      <div class="col-6 col-lg-9">
        <span style={{color:'gray',fontWeight:500,fontSize: '14px'}}>Boltzmann Labs Pvt Ltd © 2023 | All Rights Reserved
          | <a style={{color:'gray',fontWeight: 600}} href="" onClick={(e)=>{e.preventDefault()
          Nav('/Privacy_policy')}}>Privacy Policy</a> | <a
          style={{color:'gray',fontWeight:600}}  href="" onClick={(e)=>{e.preventDefault()
          Nav('/Terms')}}>Terms & Conditions</a></span>
      </div>
      <div class="col-6 col-lg-3 text-right order-lg-last" >
        <div class="social">
          <a class="social-facebook" href="https://www.facebook.com/boltzmannlabs2019"><i
              class="fa fa-facebook"></i></a>
          <a class="social-twitter" href="https://twitter.com/LabsBoltzmann"><i class="fa fa-twitter"></i></a>
          <a class="social-instagram" href="https://www.instagram.com/boltzmannlabs1/"><i
              class="fa fa-instagram"></i></a>

        </div>
      </div>

    </div>
  </div>
</footer>



 
  <button class="btn btn-circle btn-primary scroll-top"><i class="fa fa-angle-up"></i></button>

</div>
    )
}


export default Footer