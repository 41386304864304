import {useState,useEffect} from 'react'
import '../css/custom.css'
import '../css/page.min.css'
import '../css/style.css'
import '../css/partnerstyles.css'
import Accordion from 'react-bootstrap/Accordion'
import { Helmet } from 'react-helmet-async'











const Partnership = ()=>{

useEffect(()=>{
   
    window.scrollTo(0, 0)
})




return(

   <div>
  <Helmet>
    <title>Boltzmann | Pipeline</title>
    <meta property='description' content='The academic partnership program is an initiative being taken up by the company to connect Boltzmann’s AI drug discovery platform and expertise with global academic research and innovation environments. We invite academic scientists to partner with us to find new cures and bring them to the market.'/>
    <meta property='image' content = 'https://media.licdn.com/dms/image/C560BAQH0UHoJdJF5YA/company-logo_200_200/0/1646989167292?e=2147483647&v=beta&t=-yLDz_qkJed4WVBEbk2yUa-KF8SeGCUziaamPv4n3mI'/>

    {/* facebook  */}
    <meta property="og:title" content="Boltzmann-Pipeline" />
    <meta property ="og:url" content = "https://boltzmann.co/partnership" />
    <meta property="og:description" content="The academic partnership program is an initiative being taken up by the company to connect Boltzmann’s AI drug discovery platform and expertise with global academic research and innovation environments. We invite academic scientists to partner with us to find new cures and bring them to the market."/>
    <meta property='og:image' content="https://media.licdn.com/dms/image/C560BAQH0UHoJdJF5YA/company-logo_200_200/0/1646989167292?e=2147483647&v=beta&t=-yLDz_qkJed4WVBEbk2yUa-KF8SeGCUziaamPv4n3mI"/>

    {/* twitter */}

    <meta property="twitter:title" content="Boltzmann-Pipeline"/>
    <meta property='twitter:image' content ="https://media.licdn.com/dms/image/C560BAQH0UHoJdJF5YA/company-logo_200_200/0/1646989167292?e=2147483647&v=beta&t=-yLDz_qkJed4WVBEbk2yUa-KF8SeGCUziaamPv4n3mI" />
    <meta propety = 'twitter:description' content ="The academic partnership program is an initiative being taken up by the company to connect Boltzmann’s AI drug discovery platform and expertise with global academic research and innovation environments. We invite academic scientists to partner with us to find new cures and bring them to the market."  />
    <meta propert ='twitter:url' content='https://boltzmann.co/partnership'/>
    <meta name="twitter:card" content="summary_large_image"/>

  </Helmet>
   
    <main className="main-content" id="partnership">

      <section id="Pipeline" className="section section-Pipeline">
        <div className="container" style={{ marginBottom: '5%',  textAlign:'center', fontFamily:'Inter, sans-serif' }}>
         <h2 style={{color:'white',fontWeight:'bold' , marginBottom:'30px'}}>PIPELINE</h2>
         <div>
            <img src={require('../newimages/pipeline.png')} alt="pipeline"/>
         </div>
         <div style={{marginTop:'30px', fontSize:'20px', color:'white'}}>
          {/* <p>Download case study posters <a href="">Here</a> </p>
          <p>Download publications <a href="">Here</a> </p>
          <p>Download Media features <a href="">Here</a> </p> */}
         </div>
      </div>
      </section>


















      <section id="section-features" className="section section-partnership">
        <div className="container" style={{ marginBottom: '5%' }}>
          <h3 style={{ marginBottom: '36px', fontFamily: 'Inter, sans-serif', color: '#501f84', fontSize: '40px', lineHeight: '110%', fontWeight: 700, letterSpacing: 0 }}>Parternships</h3>
          <p className="aboutp"  style={{ textAlign: 'center' }}>
            <a style={{ color: 'black', borderBottom: '1px dotted black', fontSize: '17px', fontWeight: 'bold' }} href="https://forms.gle/4TLaAtN5qBoe9XWn8" target="_blank">Get In Touch.</a>
          </p>
          <div style={{ marginTop: '4%' }}>
            <p style={{ textAlign: 'justify', color: 'black', fontWeight: 500, marginTop: '2%' }} className="aboutp">Boltzmann Labs has created an AI-based drug discovery platform that can accelerate the process of therapeutics research and explore the chemical space in an efficient and user-friendly interface. Using a data-driven approach, BoltChem, our proprietary small molecule design and discovery software can be utilized to design novel drugs for any disease target de novo.</p>
            <p style={{ textAlign: 'justify', color: 'black', fontWeight: 500, marginTop: '3%' }} className="aboutp">The academic partnership program is an initiative being taken up by the company to connect Boltzmann’s AI drug discovery platform and expertise with global academic research and innovation environments. We invite academic scientists to partner with us to find new cures and bring them to the market.</p>
          </div>
          <Accordion>
            <Accordion.Item eventKey="0" style={{ backgroundColor: '#501f84', color: 'white', marginBottom:'10px' }}>
              <Accordion.Header style={{textAlign:'center'}}>
                   Why partner with us?
              </Accordion.Header>
              <Accordion.Body>
                <div className="card-body" style={{ marginLeft: '1.5%' }}>
                  <p style={{ textAlign: 'justify', color: 'white', fontWeight: 500 }} className="aboutp">BoltChem, the AI-based drug design platform offered by Boltzmann can drastically accelerate the process of designing therapeutics and exploring the chemical space in an efficient and user-friendly interface. Our team of experts are well versed with the entire flow of the drug discovery process. We understand the impact of target research can have in reducing the disease burden. We strive to deliver transparent, efficient and precise results to carry your research to the pharmaceutical market.</p>
                </div>
             </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1" style={{ backgroundColor: '#501f84', color: 'white' }}>
              <Accordion.Header style={{textAlign:'center'}}>End Goals</Accordion.Header>
               <Accordion.Body>           
                <div className="card-body" style={{ marginLeft: '2%' }}>
                  <p style={{ textAlign: 'justify', color: 'white', fontWeight: 500 }} className="aboutp">We aim to work with scientists to develop novel therapeutics that will result in shared IPs, publications and eventually lowering the disease burden by bringing our innovation to the patients in need.</p>
                  <p style={{ textAlign: 'justify', color: 'white', fontWeight: 500 }} className="aboutp">We want to create a major impact in drug discovery by bringing in the next generation innovations that can accelerate drug discovery. With partnerships, we aim to deliver the best medicines to the market faster to help the needy.</p>
                  <p style={{ textAlign: 'justify', color: 'white', fontWeight: 500 }} className="aboutp">Let’s uncover the greater potential of <span style={{ color: 'yellow' }}>AI-driven discovery</span> together!</p>
                </div>
            </Accordion.Body> 
            </Accordion.Item>
        </Accordion>
          <div>
            <h2 style={{ color: 'white', fontSize: '30px', lineHeight: '110%', fontWeight: 600, letterSpacing: 0, marginTop: '7%' }}>Partnership model</h2>
          </div>
          <div className="row">
            <div className="col-md-6">
              <button className="btn btn-outline venture-button1" type="button" onClick={() => window.location.href = 'mailto:contact@boltzmann.co'}>Academic Partnership Program</button>
              <div style={{ width: '90%', marginRight: '1%' }}>
                <p style={{ textAlign: 'justify', color: 'black', fontWeight: 500, marginTop: '4%' }} className="aboutp">Academic institutes and laboratories are invited to propose a disease or protein target for which experts will perform De Novo drug design. Best molecules shall be synthesized and validated.</p>
              </div>
            </div>
            <div className="col-md-6">
              <button className="btn btn-outline venture-button2" type="button" onClick={() => window.location.href = 'mailto:contact@boltzmann.co'}>Joint ventures</button>
              <div style={{ width: '90%', marginLeft: '1%' }}>
                <p style={{ textAlign: 'justify', color: 'black', fontWeight: 500, marginTop: '4%' }} className="aboutp">Both Boltzmann and the partner will use their computational and biological expertise to co-create effective novel therapeutics with shared risks and rewards.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
</main>

</div>



)}

export default Partnership;