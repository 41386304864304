
import '../css/style.css'
import '../css/page.min.css'
import '../css/custom.css'
import {useNavigate} from "react-router-dom"
import { useState } from 'react'




const NavigationBar = ()=>{


  const [NavOpen,setNavOpen] = useState(false)

const Nav = useNavigate()


    return (
        <nav class="navbar navbar-expand-lg" data-navbar="fixed" style={{marginTop:'0px', minHeight:'80px'}}>
    <div class="container" >
    {
      NavOpen && <div class="backdrop backdrop-navbar" onClick={()=>{document.body.classList.remove('navbar-open');setNavOpen(false)}}></div>
    }

      <div class="navbar-left">
        <button class="navbar-toggler" type="button"  onClick={()=>{document.body.classList.toggle('navbar-open');setNavOpen(true)}}><span class="navbar-toggler-icon"> <i class="fa fa-navicon"
              style={{color:'#501f84', fontSize:'28px'}}></i></span></button>
        <a class="navbar-brand" href=""
        onClick={(e)=>{
           e.preventDefault()
            Nav('/')
          }}>
          {/* <!--<img class="logo-dark" src="newimages/logo.png" alt="logo">
            <img class="logo-light" src="assets\img\logo-light.png" alt="logo">--> */}
          <img src={require("../newimages/boltzman-web-new.png")} alt="logo"   on/>
        </a>
      </div>

      <section class="navbar-mobile">
        
        <nav class="nav nav-navbar ml-auto" style={{textAlign:'left'}} >
          <button class="nav-link" onClick={()=>{
            Nav('/')
            if(NavOpen){ document.body.classList.remove('navbar-open');setNavOpen(false)}
            }} style ={{border:'none',background:'none'}}>Home</button>
          <button class="nav-link" onClick={()=>{Nav('/products');if(NavOpen){ document.body.classList.remove('navbar-open');setNavOpen(false)}}} style ={{border:'none',background:'none'}}>Products</button>
          <button class="nav-link" onClick={()=>{Nav('/about');if(NavOpen){ document.body.classList.remove('navbar-open');setNavOpen(false)}}} style ={{border:'none',background:'none'}}>About</button>
          
          <button class="nav-link" onClick={()=>{Nav('/blog');if(NavOpen){ document.body.classList.remove('navbar-open');setNavOpen(false)}}} style ={{border:'none',background:'none'}}>Blog</button>
          <button class="nav-link" onClick={()=>{Nav('/partnership');if(NavOpen){ document.body.classList.remove('navbar-open');setNavOpen(false)}}} style ={{border:'none',background:'none'}}>Pipeline</button>
          {/* <!--<a class="nav-link" href="index2.html" target="blank">FAQ</a>--> */}
          <div class="d-flex align-items-center">
          <button  class="btn btn-sm btn-outline-primary contact-button" style={{color:'white'}} onClick={(e)=>{
                Nav('/contact')
                if(NavOpen){ document.body.classList.remove('navbar-open');setNavOpen(false)}
            }}>Contact</button>
          </div>
        </nav>
      </section>

    </div>
  </nav>
    )
}

export default NavigationBar